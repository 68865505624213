<template>
<div>
    <a :href="'/qr-print/'+motor.token" target="_blank">
        <qrcode :value="kod" :options="{ width: 300, errorCorrectionLevel:'L' }"></qrcode>
    </a>


   <!-- <p>CAG HLIE2 112 0M1 02 00400 14 400 690 50 60 7300 19120001</p> - ->

    <p>{{kod}}</p>-->
</div>
</template>

<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import Vue from 'vue'
    Vue.component(VueQrcode.name, VueQrcode);

    const koder = require('../../koder.js')




    export default {
        name: "MotorQr",

        computed:{
            kod(){
                if (!this.motor.sn) return "0"
                return koder(this.motor)
            }
        },
        data(){
            return{
                motor:[]
            }
        },
        mounted() {
            console.log('mounted Options')
            this.motor = this.$parent.motor
            this.$parent.$on('motorUpdated', data => {
                this.motor = data // this.$parent.motor
            })
        },


    }

</script>

<style scoped>

</style>
